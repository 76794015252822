import { deleteRequest, patch, post } from '@osrdata/app_core/dist/requests'
import terms from 'assets/terms'
import { setSnackbar } from 'reducers/app'
import {
  deleteMission, patchAdditionalMissionActive, patchAdditionalMissionDuration, patchAdditionnalMissionName,
  patchConstructionActive,
  patchConstructionName, patchDefectActive, patchDefectName,
  patchDirectionActive,
  patchDirectionName, patchMission, patchObservationActive, patchObservationName,
  patchTrackActive,
  patchTrackName, postNewAdditionalMission, postNewConstruction, postNewDefect,
  postNewDirection, postNewMission, postNewObservation, postNewTrack,
} from 'reducers/params'
import { store } from 'reducers/store'
import { ParamsMission, ParamsTrack, SnackbarSeverity } from 'types'

export const handlePostNewConstruction = (
  centerId: string,
  callback: () => void,
) => (value: string) => () => post<{id: string, libelle: string}>(
  `/r2d2/technicentres/${centerId}/chantier/`,
  { libelle: value },
).catch(e => {
  const errorMessage = e?.response?.data?.libelle || terms.Error.params.constructionPostError
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
}).then(newConstruction => {
  if (newConstruction) {
    callback()
    store.dispatch(postNewConstruction({ centerId,
      construction: {
        id: newConstruction.id,
        libelle: newConstruction.libelle,
        voies: [],
        active: true,
      } }))
  }
})

export const handleDeleteConstruction = (centerId: string, constructionId: string) => () => deleteRequest(
  `/r2d2/technicentres/${centerId}/chantier/${constructionId}/`,
).catch(() => {
  store.dispatch(setSnackbar({ message: terms.Error.params.constructionDeleteError,
    severity: SnackbarSeverity.ERROR }))
}).then(() => {
  store.dispatch(patchConstructionActive({ centerId, constructionId, active: false }))
})

export const handleReactivateConstruction = (centerId: string, constructionId: string) => () => patch(
  `/r2d2/technicentres/${centerId}/chantier/${constructionId}/`,
  { active: true },
).then(() => {
  store.dispatch(patchConstructionActive({ centerId, constructionId, active: true }))
}).catch(() => {
  store.dispatch(setSnackbar({ message: terms.Error.params.constructionReactivateError,
    severity: SnackbarSeverity.ERROR }))
})

export const handlePatchConstructionName = (centerId: string, constructionId: string) => (libelle: string) => patch(
  `/r2d2/technicentres/${centerId}/chantier/${constructionId}/`,
  { libelle },
).then((resp: {libelle: string}) => {
  store.dispatch(patchConstructionName({ centerId, constructionId, libelle: resp.libelle }))
})

export const handlePatchTrackName = (
  centerId: string,
  constructionId: string,
  trackId: string,
) => (libelle: string) => patch(
  `/r2d2/technicentres/${centerId}/chantier/${constructionId}/voie/${trackId}/`,
  { libelle },
).then((resp: {libelle: string}) => {
  store.dispatch(patchTrackName({ centerId, constructionId, trackId, libelle: resp.libelle }))
})

export const handlePostNewTrack = (
  centerId: string,
  constructionId: string,
  callback: () => void,
) => (value: string) => () => post<ParamsTrack>(
  `/r2d2/technicentres/${centerId}/chantier/${constructionId}/voie/`,
  { libelle: value },
).catch(e => {
  const errorMessage = e?.response?.data?.libelle || terms.Error.params.trackPostError
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
}).then(newTrack => {
  if (newTrack) {
    store.dispatch(postNewTrack({ centerId,
      constructionId,
      track: newTrack }))
    callback()
  }
})

export const handleDeleteTrack = (centerId: string, constructionId: string, trackId: string) => () => deleteRequest(
  `/r2d2/technicentres/${centerId}/chantier/${constructionId}/voie/${trackId}/`,
).catch(() => {
  store.dispatch(setSnackbar({ message: terms.Error.params.trackDeleteError,
    severity: SnackbarSeverity.ERROR }))
}).then(() => {
  store.dispatch(patchTrackActive({ centerId, constructionId, trackId, active: false }))
})

export const handleReactivateTrack = (centerId: string, constructionId: string, trackId: string) => () => patch(
  `/r2d2/technicentres/${centerId}/chantier/${constructionId}/voie/${trackId}/`,
  { active: true },
).then(() => {
  store.dispatch(patchTrackActive({ centerId, constructionId, trackId, active: true }))
}).catch(() => {
  store.dispatch(setSnackbar({ message: terms.Error.params.trackReactivateError,
    severity: SnackbarSeverity.ERROR }))
})

export const handleChangeDirectionName = (centerId: string, directionId: string) => (libelle: string) => patch(
  `/r2d2/technicentres/${centerId}/sens-depart/${directionId}/`,
  { libelle },
).then((resp: {libelle: string}) => {
  store.dispatch(patchDirectionName({ centerId, directionId, libelle: resp.libelle }))
})

export const handleDeleteDirection = (centerId: string, directionId: string) => () => deleteRequest(
  `/r2d2/technicentres/${centerId}/sens-depart/${directionId}/`,
).catch(() => {
  store.dispatch(setSnackbar({ message: terms.Error.params.directionDeleteError,
    severity: SnackbarSeverity.ERROR }))
}).then(() => {
  store.dispatch(patchDirectionActive({ centerId, directionId, active: false }))
})

export const handleReactivateDirection = (centerId: string, directionId: string) => () => patch(
  `/r2d2/technicentres/${centerId}/sens-depart/${directionId}/`,
  { active: true },
).then(() => {
  store.dispatch(patchDirectionActive({ centerId, directionId, active: true }))
}).catch(() => {
  store.dispatch(setSnackbar({ message: terms.Error.params.directionReactivateError,
    severity: SnackbarSeverity.ERROR }))
})

export const handlePostNewDirection = (centerId: string, callback: () => void) => (value: string) => () => post(
  `/r2d2/technicentres/${centerId}/sens-depart/`,
  { libelle: value },
).catch(e => {
  const errorMessage = e?.response?.data?.libelle || terms.Error.params.directionPostError
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
}).then(newDirection => {
  if (newDirection) {
    store.dispatch(postNewDirection({ centerId, direction: newDirection }))
    callback()
  }
})

export const handlePostNewObservation = (callback: () => void) => (value: string) => () => post(
  '/r2d2/observation-rd/',
  { libelle: value },
).catch(e => {
  const errorMessage = e?.response?.data?.libelle || terms.Error.params.observationPostError
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
}).then(newObservation => {
  if (newObservation) {
    store.dispatch(postNewObservation(newObservation))
    callback()
  }
})

export const handleChangeObservationName = (observationId: string) => (libelle: string) => patch(
  `/r2d2/observation-rd/${observationId}/`,
  { libelle },
).then((resp: {libelle: string}) => {
  store.dispatch(patchObservationName({ id: observationId, libelle: resp.libelle }))
})

export const handleDeleteObservation = (observationId: string) => () => deleteRequest(
  `/r2d2/observation-rd/${observationId}/`,
).then(() => {
  store.dispatch(patchObservationActive({ id: observationId, active: false }))
}).catch(() => {
  store.dispatch(setSnackbar({ message: terms.Error.params.observationDeleteError,
    severity: SnackbarSeverity.ERROR }))
})

export const handleReactivateObservation = (observationId: string) => () => patch(
  `/r2d2/observation-rd/${observationId}/`,
  { active: true },
).then(() => {
  store.dispatch(patchObservationActive({ id: observationId, active: true }))
}).catch(() => {
  store.dispatch(setSnackbar({ message: terms.Error.params.observationReactivateError,
    severity: SnackbarSeverity.ERROR }))
})

export const handlePostNewDefect = (callback: () => void) => (value: string) => () => post(
  '/r2d2/defaut-rd/',
  { libelle: value },
).catch(e => {
  const errorMessage = e?.response?.data?.libelle || terms.Error.params.defectPostError
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
}).then(newDefect => {
  if (newDefect) {
    store.dispatch(postNewDefect(newDefect))
    callback()
  }
})

export const handleChangeDefectName = (defectId: string) => (libelle: string) => patch(
  `/r2d2/defaut-rd/${defectId}/`,
  { libelle },
).then((resp: {libelle: string}) => {
  store.dispatch(patchDefectName({ id: defectId, libelle: resp.libelle }))
})

export const handleDeleteDefect = (defectId: string) => () => deleteRequest(
  `/r2d2/defaut-rd/${defectId}/`,
).catch(() => {
  store.dispatch(setSnackbar({ message: terms.Error.params.defectDeleteError,
    severity: SnackbarSeverity.ERROR }))
}).then(() => {
  store.dispatch(patchDefectActive({ id: defectId, active: false }))
})

export const handleReactivateDefect = (defectId: string) => () => patch(
  `/r2d2/defaut-rd/${defectId}/`,
  { active: true },
).then(() => {
  store.dispatch(patchDefectActive({ id: defectId, active: true }))
}).catch(() => {
  store.dispatch(setSnackbar({ message: terms.Error.params.defectReactivateError,
    severity: SnackbarSeverity.ERROR }))
})

export const handlePostAdditionalMission = (callback: () => void) => (label: string, duration: string) => () => post(
  '/r2d2/mission-supplementaire-modele/',
  { libelle: label, duree_theorique: parseInt(duration, 10) },
).catch(e => {
  const errorMessage = e?.response?.data?.libelle || e?.response?.data?.duree_theorique
  || terms.Error.params.additionalMissionPostError
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
}).then(newMission => {
  if (newMission) {
    store.dispatch(postNewAdditionalMission(newMission))
    callback()
  }
})

export const handleDeleteAdditionalMission = (missionId: string) => () => deleteRequest(
  `/r2d2/mission-supplementaire-modele/${missionId}/`,
).then(() => {
  store.dispatch(patchAdditionalMissionActive({ id: missionId, active: false }))
}).catch(() => {
  store.dispatch(setSnackbar({ message: terms.Error.params.additionalMissionDeleteError,
    severity: SnackbarSeverity.ERROR }))
})

export const handleReactivateAdditionalMission = (missionId: string) => () => patch(
  `/r2d2/mission-supplementaire-modele/${missionId}/`,
  { active: true },
).then(() => {
  store.dispatch(patchAdditionalMissionActive({ id: missionId, active: true }))
}).catch(() => {
  store.dispatch(setSnackbar({ message: terms.Error.params.missionReactivateError,
    severity: SnackbarSeverity.ERROR }))
})

export const handlePatchAdditionalMissionName = (missionId: string) => (libelle: string) => patch(
  `/r2d2/mission-supplementaire-modele/${missionId}/`,
  { libelle },
).then((resp: {libelle: string}) => {
  store.dispatch(patchAdditionnalMissionName({ id: missionId, libelle: resp.libelle }))
})

export const handlePatchAdditionalMissionDuration = (missionId: string) => (duration: string) => patch(
  `/r2d2/mission-supplementaire-modele/${missionId}/`,
  { duree_theorique: duration },
).then((resp: {duree_theorique: number}) => {
  store.dispatch(patchAdditionalMissionDuration({ id: missionId, duree_theorique: resp.duree_theorique }))
})

export const handlePostNewMission = (callback: () => void) => (newMission: Omit<ParamsMission, 'id'>) => () => post(
  '/r2d2/mission-modele/',
  newMission,
).catch(e => {
  const errorMessage = (e?.response?.data && (Object.values(e.response.data) as string[][]).flatMap(s => s).join('. '))
  || terms.Error.params.missionPostError
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
}).then((resp: ParamsMission) => {
  if (resp) {
    store.dispatch(postNewMission(resp))
    callback()
  }
})

export const handleDeleteMission = (missionId: string) => () => deleteRequest(
  `/r2d2/mission-modele/${missionId}/`,
).then(() => {
  store.dispatch(deleteMission(missionId))
}).catch(() => {
  store.dispatch(setSnackbar({ message: terms.Error.params.missionDeleteError,
    severity: SnackbarSeverity.ERROR }))
})

export const handleReactivateMission = (missionId: string) => () => patch(
  `/r2d2/mission-modele/${missionId}/`,
  { active: true },
).then(() => {
  store.dispatch(patchMission({ id: missionId, mission: { active: true } }))
}).catch(() => {
  store.dispatch(setSnackbar({ message: terms.Error.params.missionReactivateError,
    severity: SnackbarSeverity.ERROR }))
})

export const handlePatchMission = (missionId: string, mission: Partial<ParamsMission>) => patch(
  `/r2d2/mission-modele/${missionId}/`,
  mission,
).then((resp: Partial<ParamsMission>) => {
  store.dispatch(patchMission({ id: missionId, mission: resp }))
})
