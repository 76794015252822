import { Button, ButtonStyle, ModalConfirm, ModalError, modalSignal } from 'components'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getMissionRD } from 'reducers/rd/thunks'
import {
  handleEndTestsRD, handlePresenceOnEngineRD,
  handleRefuseMissionRD, handleStartMissionRD, handleStartTestsRD, handleValidateMissionRD, routes,
} from 'services'
import { MissionRD, MissionRDProgress } from 'types'
import { useAppDispatch } from 'utils'
import BackButton from './BackButton'

interface Props {
  mission: MissionRD
  technicalCenterId: string
  progress: MissionRDProgress
  additionalMissions: MissionRD['missions_supplementaires']
}

export default function MissionActions({ mission, technicalCenterId, progress, additionalMissions }: Props) {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [expanded, setExpanded] = useState(false)
  const [cancelComment, setCancelComment] = useState('')

  const handleStart = handleStartMissionRD(
    technicalCenterId,
    mission.id,
    () => {
      navigate(`${routes.rd}${routes.rdMissionsList}/inprogress/${mission.id}`)
      dispatch(getMissionRD({ technicenterId: technicalCenterId, idMission: mission.id }))
    },
  )

  const handleEnginePresence = handlePresenceOnEngineRD(
    technicalCenterId,
    mission.id,
    () => { dispatch(getMissionRD({ technicenterId: technicalCenterId, idMission: mission.id })) },
  )

  const handleStartTests = handleStartTestsRD(
    technicalCenterId,
    mission.id,
    () => { dispatch(getMissionRD({ technicenterId: technicalCenterId, idMission: mission.id })) },
  )

  const handleEndTests = handleEndTestsRD(
    technicalCenterId,
    mission.id,
    () => { dispatch(getMissionRD({ technicenterId: technicalCenterId, idMission: mission.id })) },
  )

  const handleValidate = () => {
    const notDoneAM = additionalMissions?.find(am => !am.done)
    if (!mission.orientation_motrice?.principale || (mission.um && !mission.orientation_motrice?.secondaire)) {
      modalSignal.value = (
        <ModalError
          title="Veuillez renseigner l'orientation des rames"
          handleClose={() => { modalSignal.value = null }}
        />
      )
    } else if (notDoneAM) {
      modalSignal.value = (
        <ModalConfirm
          // eslint-disable-next-line max-len
          title="Certaines missions supplémentaires n’ont pas été validées, êtes-vous sûr de vouloir valider la mission principale?"
          handleClose={() => { modalSignal.value = null }}
          handleValidate={handleValidateMissionRD(
            technicalCenterId,
            mission.id,
            () => {
              dispatch(getMissionRD({ technicenterId: technicalCenterId, idMission: mission.id }))
              modalSignal.value = null
              navigate(`${routes.rd}${routes.rdMissionsList}/validee/${mission.id}`)
            },
          )}
        />
      )
    } else {
      handleValidateMissionRD(
        technicalCenterId,
        mission.id,
        () => {
          dispatch(getMissionRD({ technicenterId: technicalCenterId, idMission: mission.id }))
          navigate(`${routes.rd}${routes.rdMissionsList}/validee/${mission.id}`)
        },
      )()
    }
  }

  const handleCancel = handleRefuseMissionRD(
    technicalCenterId,
    mission.id,
    () => {
      navigate(routes.rd)
      dispatch(getMissionRD({ technicenterId: technicalCenterId, idMission: mission.id }))
    },
    cancelComment,
  )

  return (
    <div className="rd-mission-actions">
      {expanded && (
        <div className="rd-refuse-mission">
          <BackButton onClick={() => setExpanded(false)} />
          <strong>Refus de la mission</strong>
          <span>Complément</span>
          <textarea
            value={cancelComment}
            onChange={e => setCancelComment(e.target.value)}
            rows={3}
          />
          <Button
            fullWidth
            text="Refuser la mission"
            onClick={handleCancel}
            style={ButtonStyle.danger}
            disabled={!cancelComment.trim()}
          />
        </div>
      )}
      {progress === MissionRDProgress.notStarted && (
        <Button
          fullWidth
          text="Prendre la mission"
          onClick={handleStart}
        />
      )}
      {progress === MissionRDProgress.started && (
        <>
          <Button
            fullWidth
            text="Présence sur l'engin"
            onClick={handleEnginePresence}
          />
          <span>
            Signalez votre présence sur l’engin pour pouvoir noter des défauts constatés
          </span>
        </>
      )}
      {progress === MissionRDProgress.atEngine && (
        <Button
          fullWidth
          text="Prêt au départ"
          onClick={handleStartTests}
        />
      )}
      {([MissionRDProgress.started, MissionRDProgress.notStarted, MissionRDProgress.atEngine].includes(progress)) && (
        <Button
          fullWidth
          text="Refuser la mission"
          onClick={() => setExpanded(true)}
          style={ButtonStyle.danger}
        />
      )}
      {progress === MissionRDProgress.testsStarted && (
        <Button
          fullWidth
          text="Essais et missions terminés"
          onClick={handleEndTests}
          style={ButtonStyle.success}
        />
      )}
      {progress === MissionRDProgress.testsEnded && (
        <Button
          fullWidth
          text="Valider la mission"
          onClick={handleValidate}
          style={ButtonStyle.success}
        />
      )}
    </div>
  )
}
