import { MissionRD, MissionRDProgress, MissionStatus, ParamsDefects, ParamsObservations } from 'types'
import { useAppDispatch, useAppSelector } from 'utils'
import { handlePatchAdditionnalMissionsRD, handlePatchMissionRD } from 'services'
import { getMissionRD } from 'reducers/rd/thunks'
import DataPanel from './DataPanel'
import OrientationSelection from './OrientationSelection'
import DataPanelMissions from './DataPanelMissions'

const getDetails = (noValue: string, details?: string[], comment?: string) => {
  const strings = [...details, comment].filter(Boolean)
  return strings.length ? strings.join(', ') : noValue
}
interface Props {
  technicalCenterId: string
  mission: MissionRD
  missionProgress: MissionRDProgress
}

export default function MissionAdditionalData({ technicalCenterId, mission, missionProgress }: Props) {
  const dispatch = useAppDispatch()
  const { defects, observations } = useAppSelector(state => state.params)

  const handlePatchDefects = (newDefects: ParamsDefects[], comment: string) => handlePatchMissionRD(
    technicalCenterId,
    mission.id,
    {
      defauts_rd: newDefects.map(defaut => defaut.id) as unknown as ParamsDefects[],
      commentaire_defauts_rd: comment,
    },
    () => { dispatch(getMissionRD({ technicenterId: technicalCenterId, idMission: mission.id })) },
  )

  const handlePatchObservations = (newObservations: ParamsObservations[], comment: string) => handlePatchMissionRD(
    technicalCenterId,
    mission.id,
    {
      observations_rd: newObservations.map(observation => observation.id) as unknown as ParamsObservations[],
      commentaire_observation_rd: comment,
    },
    () => { dispatch(getMissionRD({ technicenterId: technicalCenterId, idMission: mission.id })) },
  )

  const handlePatchAdditionalMissions = (
    newMissions: {id: string, libelle: string}[],
  ) => handlePatchAdditionnalMissionsRD(
    technicalCenterId,
    mission.id,
    mission?.missions_supplementaires.map(mis => ({ id: mis.id, done: !!newMissions.find(m => m.id === mis.id) })),
    () => { dispatch(getMissionRD({ technicenterId: technicalCenterId, idMission: mission.id })) },
  )

  const displayOrientation = () => {
    if (mission?.statut === MissionStatus.validated) return true
    if (mission?.statut === MissionStatus.canceled || mission?.statut === MissionStatus.deleted) return false
    return ![MissionRDProgress.started, MissionRDProgress.notStarted].includes(missionProgress)
  }

  const displayDefects = () => {
    if ([MissionStatus.validated, MissionStatus.canceled, MissionStatus.deleted]
      .includes(mission?.statut as MissionStatus)) return true
    return ![MissionRDProgress.started, MissionRDProgress.notStarted].includes(missionProgress)
  }

  const displayAdditionalMissions = () => {
    if ([MissionStatus.validated, MissionStatus.canceled, MissionStatus.deleted]
      .includes(mission?.statut as MissionStatus)) return true
    return missionProgress !== MissionRDProgress.notStarted
  }

  return (
    <div className="rd-mission-additional-data">
      {displayOrientation() && (
        <OrientationSelection
          technicalCenterId={technicalCenterId}
          mission={mission}
          disabled={mission?.statut === MissionStatus.validated}
        />
      )}
      {displayDefects() && (
        <>
          <DataPanel
            title="Défauts constatés"
            details={
                getDetails('Aucun', mission?.defauts_rd.map(defaut => defaut.libelle), mission.commentaire_defauts_rd)
              }
            options={defects}
            missionComment={mission.commentaire_defauts_rd || ''}
            selectedOptions={mission?.defauts_rd || []}
            handleValidate={handlePatchDefects}
            disabled={mission?.statut !== MissionStatus.inProgress}
          />
          <DataPanel
            title="Observations RD"
            details={getDetails('Aucune', mission?.observations_rd.map(
              observation => observation.libelle,
            ), mission.commentaire_observation_rd)}
            options={observations}
            missionComment={mission.commentaire_observation_rd || ''}
            selectedOptions={mission?.observations_rd || []}
            handleValidate={handlePatchObservations}
            disabled={mission?.statut !== MissionStatus.inProgress}
          />
        </>
      )}
      {displayAdditionalMissions() && (
      <DataPanelMissions
        missions={mission?.missions_supplementaires || []}
        options={mission?.missions_supplementaires.map(m => ({ id: m.id, libelle: m.modele.libelle })) || []}
        selectedOptions={mission?.missions_supplementaires.filter(m => m.done)
          .map(m => ({ id: m.id, libelle: m.modele.libelle })) || []}
        handleValidate={handlePatchAdditionalMissions}
        disabled={mission?.missions_supplementaires.length === 0 || mission?.statut !== MissionStatus.inProgress}
      />
      )}
    </div>
  )
}
