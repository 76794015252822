import terms from 'assets/terms'
import Button, { ButtonStyle } from 'components/button/Button'
import './ModalAddComment.scss'
import { useState } from 'react'

interface Props {
  title: string;
  handleClose: () => void;
  handleValidate: (comment: string) => () => void;
}

export default function ModalAddComment({ title, handleClose, handleValidate }: Props) {
  const [comment, setComment] = useState('')
  return (
    <div className="modal-add-comment">
      <h3>{title}</h3>
      <textarea
        draggable={false}
        rows={3}
        value={comment}
        onChange={e => setComment(e.target.value)}
      />
      <div className="actions">
        <Button text={terms.Common.cancel} onClick={handleClose} style={ButtonStyle.light} />
        <Button text={terms.Common.continue} onClick={handleValidate(comment)} />
      </div>
    </div>
  )
}
