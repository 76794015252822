import { useEffect, useState } from 'react'
import { getMissionAgents, getMissionTeams, getMissionsTC, getTCFilters } from 'reducers/missionTable/thunks'
import { getMissions, getTechnicalCenters, getAdditionalMissions, getDefects } from 'reducers/params/thunks'
import { AppRoles } from 'types'
import { useAppDispatch, useAppSelector, useCOMNotif, usePageRoles } from 'utils'
import Header from './header/Header'
import MissionsTable from './missionsTable/MissionTable'

import './MissionTablePage.scss'
import NotificationsDrawer from './notificationsDrawer/NotificationsDrawer'

export default function MissionTablePage() {
  useCOMNotif()
  const dispatch = useAppDispatch()
  usePageRoles([AppRoles.consultant, AppRoles.com])
  const { activeRole } = useAppSelector(state => state.app)
  const [technicalCenterId, setTechnicalCenterId] = useState<string>('')
  const [selectedMissions, setSelectedMissions] = useState<string[]>([])
  const [missionsOrdering, setMissionsOrdering] = useState<string>('heure_debut_theorique')

  useEffect(() => {
    if (activeRole && activeRole.role !== AppRoles.admin) {
      dispatch(getMissionTeams(activeRole.technicenterId))
      dispatch(getMissionAgents(activeRole.technicenterId))
      dispatch(getMissionsTC({ technicenterId: activeRole.technicenterId, ordering: missionsOrdering }))
      dispatch(getAdditionalMissions())
      dispatch(getMissions())
      dispatch(getDefects())
      dispatch(getTechnicalCenters())
      setTechnicalCenterId(activeRole.technicenterId)
      dispatch(getTCFilters(activeRole.technicenterId))
    }
  }, [activeRole])

  return (
    <div id="mt-page" className="page">
      <NotificationsDrawer />
      <Header
        technicalCenterId={technicalCenterId}
        selectedMissions={selectedMissions}
        setSelectedMissions={setSelectedMissions}
        missionsOrdering={missionsOrdering}
      />
      <MissionsTable
        technicalCenterId={technicalCenterId}
        selectedMissions={selectedMissions}
        setSelectedMissions={setSelectedMissions}
        missionsOrdering={missionsOrdering}
        setMissionsOrdering={setMissionsOrdering}
      />
    </div>
  )
}
