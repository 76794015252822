import { ArrowForwardIos } from '@mui/icons-material'
import { useState } from 'react'
import { MissionRD } from 'types'
import BackButton from './BackButton'
import './DataPanelMissions.scss'
import FormAdditionalMissions from './FormAdditionalMissions'

type Option = {
  id: string
  libelle: string
}

interface Props {
  missions: MissionRD['missions_supplementaires']
  options: Option[]
  selectedOptions: Option[]
  handleValidate: (options: Option[]) => () => void
  disabled?: boolean
}

export default function DataPanelMissions({ missions, options, selectedOptions, handleValidate, disabled }: Props) {
  const [expanded, setExpanded] = useState(false)

  return (
    <div className="rd-data-panel-missions">
      <button type="button" onClick={() => setExpanded(true)} disabled={disabled}>
        <div className="text">
          <strong>Missions supplémentaires</strong>
          {missions.filter(m => !m.done).length > 0 ? (
            <span className="count">
              {missions.filter(m => !m.done).length}
              {' '}
              à valider
            </span>
          ) : (
            <span>{missions.length === 0 ? 'Aucune' : 'Toutes les missions supplémentaires sont validées'}</span>
          )}
          <div>
            {missions.map((m, index) => (
              <span key={m.id} style={{ color: m.done ? '#43cf7b' : '#000' }}>
                {index > 0 && ', '}
                {m.modele.libelle}
              </span>
            )) }
          </div>
        </div>
        {!disabled && (
          <ArrowForwardIos />
        )}
      </button>
      {expanded && (
        <div className="expanded-data-panel">
          <BackButton onClick={() => setExpanded(false)} />
          <FormAdditionalMissions
            count={missions.filter(m => !m.done).length}
            options={options}
            selectedOptions={selectedOptions}
            handleValidate={handleValidate}
            handleClose={() => setExpanded(false)}
          />
        </div>
      )}
    </div>
  )
}
