/* eslint-disable max-len */
import { ReactElement, useEffect, useRef } from 'react'
import { GATEWAY_DOMAIN } from 'config/config'
import { handleNotificationMessage } from 'services'
import { useAppSelector } from 'utils'

interface Props {
  centerId: string
  missionsOrdering: string
}

export default function NotificationsStream({
  centerId, missionsOrdering,
}: Props): ReactElement {
  const ws = useRef<WebSocket>()
  const { id } = useAppSelector(state => state.user.account)

  useEffect(() => {
    if (centerId) {
      ws.current = new WebSocket(
        `wss://${GATEWAY_DOMAIN.url}/r2d2/technicentres/${centerId}/connect/?_auth_token=${`${localStorage.getItem(
          'access_token',
        )}`}&user=${id}`,
      )
      ws.current.onmessage = (event: { data: string }) => {
        const newMessage = JSON.parse(event.data)
        handleNotificationMessage(newMessage, centerId, missionsOrdering)
      }
    }

    return () => {
      if (ws.current) {
        ws.current.close()
      }
    }
  }, [centerId])

  return (
    <> </>
  )
}
