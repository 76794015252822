import { Button, notificationsSignal } from 'components'
import { signal } from '@preact/signals-react'
import 'moment/locale/fr'
import {
  Stack, Checkbox, Drawer, Box, FormControlLabel,
} from '@mui/material'
import * as moment from 'moment'
import { Close } from '@mui/icons-material'
import { notificationsTypesSignal } from 'components/notifications/NotificationWrapper'
import { MissionNotificationType } from 'types'

moment.locale('fr')

export const notificationsDrawerSignal = signal<boolean>(false)

// 'MODIFICATION' | 'REFUS' | 'SUPPRESSION' | 'CREATION' | 'AFFECTATION' | 'VALIDATION' | 'ARCHIVAGE'
const checkboxes: {
  label: string;
  color: string;
  types: MissionNotificationType[];
}[] = [
  {
    label: 'Création',
    color: '#3B38DA',
    types: ['CREATION'],
  },
  {
    label: 'Modification',
    color: '#FAB142',
    types: ['MODIFICATION'],
  },
  {
    label: 'Refus / Suppression',
    color: '#DA4238',
    types: ['REFUS', 'SUPPRESSION'],
  },
  {
    label: 'Validation',
    color: '#43CF7B',
    types: ['VALIDATION'],
  },
  {
    label: 'Archivage',
    color: '#3A4457',
    types: ['ARCHIVAGE'],
  },
]
export default function NotificationsDrawer() {
  const toggleDrawer = (newOpen: boolean) => () => {
    notificationsDrawerSignal.value = newOpen
  }

  const DrawerList = (
    <Box role="presentation" p={3} borderRadius={5}>
      <Box mb={3} fontWeight={800} fontSize={20} borderRadius={5}>
        <span>NOTIFICATIONS</span>
      </Box>
      <Stack direction="row">
        {checkboxes.map(cb => (
          <FormControlLabel
            sx={{ color: cb.color }}
            key={cb.label}
            control={(
              <Checkbox
                size="small"
                sx={{
                  color: '#D7E1F3',
                  '&.Mui-checked': {
                    color: cb.color,
                  },
                }}
                checked={notificationsTypesSignal.value.some(type => cb.types.includes(type))}
                onChange={() => {
                  if (notificationsTypesSignal.value.some(type => cb.types.includes(type))) {
                    notificationsTypesSignal.value = notificationsTypesSignal.value
                      .filter(type => !cb.types.includes(type))
                  } else {
                    notificationsTypesSignal.value = [...notificationsTypesSignal.value, ...cb.types]
                  }
                }}
              />
            )}
            label={(
              <Box fontWeight={800} color={cb.color}>
                <span>{cb.label}</span>
              </Box>
)}
          />
        ))}
      </Stack>
      <Box mt={1} mb={3}>
        <Button
          text="Tout marquer comme lu"
          onClick={() => {
            notificationsSignal.value = []
          }}
        />
      </Box>

      {notificationsSignal.value.map(not => (
        <Box
          key={not.id}
          display="flex"
          justifyContent="space-between"
          mt={2}
          borderTop="1px solid rgba(0, 0, 0, 0.12)"
          pt={2}
        >
          <Box display="flex" flexDirection="column">
            <Box fontSize={20}>
              Mission
              {' '}
              {not.numero}
              {' '}
              :
              {' '}
              {not.type}
              {' '}
              par le
              {' '}
              {not.origine}
            </Box>
            <Box fontSize={20} fontWeight={800}>
              {moment(not.timestamp).format('ddd DD MMM YYYY')}
              {' '}
              à
              {' '}
              {moment(not.timestamp).format('HH:mm')}
            </Box>
          </Box>
          <button
            type="button"
            style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}
            onClick={() => {
              notificationsSignal.value = notificationsSignal.value.filter(n => n.id !== not.id)
            }}
          >
            <Close />
          </button>
        </Box>
      ))}
    </Box>
  )

  return (
    <div className="notif-drawer">
      <Drawer
        open={notificationsDrawerSignal.value}
        onClose={toggleDrawer(false)}
        anchor="right"
        PaperProps={{
          sx: {
            marginTop: 18,
            borderTopLeftRadius: 8,
          },
        }}
      >
        {DrawerList}
      </Drawer>
    </div>
  )
}
