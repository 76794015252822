import { patch } from '@osrdata/app_core/dist/requests'
import moment from 'moment'
import terms from 'assets/terms'
import { parseErrorMessage } from 'utils'
import { store } from 'reducers/store'
import { setSnackbar } from 'reducers/app'
import { MissionRD, MissionRDProgress, MissionStatus, SnackbarSeverity } from 'types'

export const getMissionRDProgress = (mission: MissionRD): MissionRDProgress => {
  if (mission?.statut !== MissionStatus.inProgress) return MissionRDProgress.notStarted
  if (mission?.fin_npd) return MissionRDProgress.testsEnded
  if (mission?.debut_npd) return MissionRDProgress.testsStarted
  if (mission?.presence_sur_engin) return MissionRDProgress.atEngine
  if (mission?.prise_en_compte) return MissionRDProgress.started
  return MissionRDProgress.notStarted
}

export const handleStartMissionRD = (
  centerId: string,
  missionId: string,
  callback: () => void,
) => () => patch(
  `/r2d2/technicentres/${centerId}/missions/${missionId}/acceptation/`,
  {
    prise_en_compte: moment().format(),
  },
).then(() => {
  callback()
}).catch(e => {
  const errorMessage = parseErrorMessage(e, terms.Messages.errors.rd.acceptMission)
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
})

export const handlePresenceOnEngineRD = (
  centerId: string,
  missionId: string,
  callback: () => void,
) => () => patch(
  `/r2d2/technicentres/${centerId}/missions/${missionId}/engin/`,
  {
    presence_sur_engin: moment().format(),
  },
).then(() => {
  callback()
}).catch(e => {
  const errorMessage = parseErrorMessage(e, terms.Messages.errors.rd.presenceOnEngine)
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
})

export const handleStartTestsRD = (
  centerId: string,
  missionId: string,
  callback: () => void,
) => () => patch(
  `/r2d2/technicentres/${centerId}/missions/${missionId}/npd/`,
  {
    debut_npd: moment().format(),
  },
).then(() => {
  callback()
}).catch(e => {
  const errorMessage = parseErrorMessage(e, terms.Messages.errors.rd.startTests)
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
})

export const handleEndTestsRD = (
  centerId: string,
  missionId: string,
  callback: () => void,
) => () => patch(
  `/r2d2/technicentres/${centerId}/missions/${missionId}/fin-npd/`,
  {
    fin_npd: moment().format(),
  },
).then(() => {
  callback()
}).catch(e => {
  const errorMessage = parseErrorMessage(e, terms.Messages.errors.rd.endTests)
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
})

export const handleValidateMissionRD = (
  centerId: string,
  missionId: string,
  callback: () => void,
) => () => patch(
  `/r2d2/technicentres/${centerId}/missions/${missionId}/validation/`,
  {
    validation: moment().format(),
  },
).then(() => {
  callback()
}).catch(e => {
  const errorMessage = parseErrorMessage(e, terms.Messages.errors.rd.validateMission)
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
})

export const handleRefuseMissionRD = (
  centerId: string,
  missionId: string,
  callback: () => void,
  comment: string,
) => () => patch(
  `/r2d2/technicentres/${centerId}/missions/${missionId}/refus/`,
  {
    commentaire_observation_rd: comment,
  },
).then(() => {
  callback()
}).catch(e => {
  const errorMessage = parseErrorMessage(e, terms.Messages.errors.rd.refuseMission)
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
})

export const handlePatchMissionRD = (
  centerId: string,
  missionId: string,
  payload: Partial<MissionRD>,
  callback: () => void,
) => () => patch(
  `/r2d2/technicentres/${centerId}/missions/${missionId}/`,
  { ...payload },
).then(callback).catch(e => {
  const errorMessage = parseErrorMessage(e, terms.Messages.errors.rd.patchMission)
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
})

export const handlePatchAdditionnalMissionsRD = (
  centerId: string,
  missionId: string,
  newAdditionnalMissions: {id: string, done: boolean}[],
  callback: () => void,
) => () => Promise.all(newAdditionnalMissions.map(({ id, done }) => patch(
  `/r2d2/technicentres/${centerId}/missions/${missionId}/missions-supplementaires/${id}/`,
  { done },
))).then(callback).catch(e => {
  const errorMessage = parseErrorMessage(e, terms.Messages.errors.missionTable.patchAdditionnalMissionsTC)
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
})
