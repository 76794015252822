import { Button, ButtonStyle, Checkbox } from 'components'
import { useState } from 'react'

type Option = {
  id: string
  libelle: string
}

interface Props {
  title: string
  missionComment: string
  options: Option[]
  selectedOptions: Option[]
  handleValidate: (options: Option[], comment: string) => () => void
  handleClose: () => void
}

export default function SelectForm({
  title, missionComment, options, selectedOptions, handleValidate, handleClose,
}: Props) {
  const [newOptions, setNewOptions] = useState<Option[]>(selectedOptions)
  const [comment, setComment] = useState(missionComment)

  return (
    <div className="rd-mission-select-form">
      <strong>{title}</strong>
      <div className="options">
        {options.map(option => (
          <button
            type="button"
            key={option.id}
            onClick={() => {
              if (newOptions.map(o => o.id).includes(option.id)) {
                setNewOptions(newOptions.filter(o => o.id !== option.id))
              } else {
                setNewOptions([...newOptions, option])
              }
            }}
          >
            <Checkbox checked={newOptions.map(o => o.id).includes(option.id)} readonly />
            {option.libelle}
          </button>
        ))}
      </div>
      <div className="comment">
        <strong>Autre</strong>
        <textarea
          draggable={false}
          rows={3}
          value={comment}
          onChange={e => setComment(e.target.value)}
        />
      </div>
      <div className="actions">
        <Button text="Annuler" onClick={handleClose} style={ButtonStyle.light} fullWidth />
        <Button text="Enregistrer" onClick={handleValidate(newOptions, comment)} fullWidth />
      </div>
    </div>
  )
}
